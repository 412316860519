<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const prefersColor = window.matchMedia('(prefers-color-scheme: dark)');
    document.body.classList.toggle('dark', prefersColor.matches);
  }
});
</script>