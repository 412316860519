<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar>
        <ion-tab-button tab="slaptrack" href="/tabs/slaptrack">
          <ion-icon :icon="handLeft" />
          <ion-label>SlapTrack</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="settings" href="/tabs/settings">
          <ion-icon :icon="settings" />
          <ion-label>Settings</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="help" href="/tabs/help">
          <ion-icon :icon="help" />
          <ion-label>Help</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { settings, help, handLeft } from 'ionicons/icons';
import { getDarkModeAsync } from '@/composables/darkMode';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    getDarkModeAsync();
    return {
      settings, 
      help, 
      handLeft,
    }
  }
}
</script>