import { Storage } from '@capacitor/core';

const DARK_MODE_STORAGE = 'dark_mode';

export async function getDarkModeAsync(): Promise<{ value: boolean }> {
    const prefersColor = window.matchMedia('(prefers-color-scheme: dark)');
    const darkJson = await Storage.get({ key: DARK_MODE_STORAGE });
    const dark = darkJson.value ? JSON.parse(darkJson.value) : { value: prefersColor.matches };
    document.body.classList.toggle('dark', dark.value);
    return dark;
}

export async function updateDarkModeAsync(dark: { value: boolean }) {
    await Storage.set({
        key: DARK_MODE_STORAGE,
        value: JSON.stringify(dark)
    });
}
