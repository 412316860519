
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { settings, help, handLeft } from 'ionicons/icons';
import { getDarkModeAsync } from '@/composables/darkMode';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    getDarkModeAsync();
    return {
      settings, 
      help, 
      handLeft,
    }
  }
}
